import React from 'react';
import { Tabs, Radio } from 'antd';

const { TabPane } = Tabs;

export default class Demo extends React.Component<any, any> {
    public state = { size: 'small' };

    public render() {
        const { size } = this.state;
        return (
            <div style={{ padding: 20 }}>
                <h2>选项卡</h2>
                <div>
                    <Radio.Group
                        value={size}
                        onChange={e => {
                            this.setState({ size: e.target.value });
                        }}
                        style={{ marginBottom: 16 }}
                    >
                        <Radio.Button value="small">Small</Radio.Button>
                        <Radio.Button value="default">Default</Radio.Button>
                        <Radio.Button value="large">Large</Radio.Button>
                    </Radio.Group>
                    <Tabs defaultActiveKey="1" size={this.state.size as any}>
                        <TabPane tab="Tab 1" key="1">
                            Content of tab 1
                        </TabPane>
                        <TabPane tab="Tab 2" key="2">
                            Content of tab 2
                        </TabPane>
                        <TabPane tab="Tab 3" key="3">
                            Content of tab 3
                        </TabPane>
                    </Tabs>
                </div>
            </div>
        );
    }
}
