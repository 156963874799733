import React from 'react';
import { Collapse, Icon, Select } from 'antd';

const { Panel } = Collapse;
const { Option } = Select;

const text = `
  A dog is a type of domesticated animal.
  Known for its loyalty and faithfulness,
  it can be found as a welcome guest in many households across the world.
`;

const genExtra = () => (
    <Icon
        type="setting"
        onClick={event => {
            // If you don't want click extra trigger collapse, you can prevent this:
            event.stopPropagation();
        }}
    />
);

export default class Demo extends React.Component<any, any> {
    public state = {
        expandIconPosition: 'left',
    };

    public render() {
        const { expandIconPosition } = this.state;
        return (
            <div style={{ padding: 20 }}>
                <h2>折叠面板</h2>
                <div>
                    <Collapse
                        defaultActiveKey={['1']}
                        expandIconPosition={
                            this.state.expandIconPosition as any
                        }
                    >
                        <Panel
                            header="This is panel header 1"
                            key="1"
                            extra={genExtra()}
                        >
                            <div>{text}</div>
                        </Panel>
                        <Panel
                            header="This is panel header 2"
                            key="2"
                            extra={genExtra()}
                        >
                            <div>{text}</div>
                        </Panel>
                        <Panel
                            header="This is panel header 3"
                            key="3"
                            extra={genExtra()}
                        >
                            <div>{text}</div>
                        </Panel>
                    </Collapse>
                    <br />
                    <span>Expand Icon Position: </span>
                    <Select
                        value={this.state.expandIconPosition}
                        onChange={(expandIconPosition: any) => {
                            this.setState({ expandIconPosition });
                        }}
                    >
                        <Option value="left">left</Option>
                        <Option value="right">right</Option>
                    </Select>
                </div>
            </div>
        );
    }
}
