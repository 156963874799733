import React from 'react';
import { Drawer } from 'antd';
import { DrawerProps } from 'antd/lib/drawer';
import './drawer.scss';

export const AntDrawer: React.FC<DrawerProps> = props => {
    const { title: inputTitle } = props;
    const title = (
        <>
            <span>{inputTitle}</span>
        </>
    );
    return (
        <Drawer
            className="maimai-crm-drawer"
            maskClosable={true}
            {...props}
            closable
            title={title}
        />
    );
};
