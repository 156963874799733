import React, { Component } from 'react';
import { RouteComponentProps } from '@reach/router';

import AntdBtnDemo from './button';
import AntdFormDemo from './form';
import AntdTableDemo from './table';
import AntdTabsDemo from './tabs';
import AntdAlertDemo from './alert';
import AntdCollapseDemo from './collapse';

export default class AntdDemo extends Component<RouteComponentProps<{}>> {
    public render() {
        return (
            <div>
                <AntdBtnDemo />
                <AntdFormDemo />
                <AntdTableDemo />
                <AntdTabsDemo />
                <AntdAlertDemo />
                <AntdCollapseDemo />
            </div>
        );
    }
}
