import React, { Component } from 'react';
import { Button, Radio, Icon } from 'antd';

type TButtonSize = 'small' | 'default' | 'large' | undefined;
interface IButtonState {
    size: TButtonSize;
    loading: boolean;
    iconLoading: boolean;
}
export default class AntdBtnDemo extends Component<{}, IButtonState> {
    public state: IButtonState = {
        size: 'large',
        loading: false,
        iconLoading: false,
    };

    public enterLoading = () => {
        this.setState({ loading: true });
    };

    public enterIconLoading = () => {
        this.setState({ iconLoading: true });
    };

    public render() {
        const { size } = this.state;
        return (
            <div style={{ padding: 20 }}>
                <h2>按钮</h2>
                <div>
                    <Radio.Group
                        value={size}
                        onChange={e => {
                            this.setState({ size: e.target.value });
                        }}
                    >
                        <Radio.Button value="large">Large</Radio.Button>
                        <Radio.Button value="default">Default</Radio.Button>
                        <Radio.Button value="small">Small</Radio.Button>
                    </Radio.Group>
                    <br />
                    <br />
                    <Button type="primary" size={size}>
                        Primary
                    </Button>
                    <Button size={size}>Normal</Button>
                    <Button type="dashed" size={size}>
                        Dashed
                    </Button>
                    <Button type="danger" size={size}>
                        Danger
                    </Button>
                    <Button type="link" size={size}>
                        Link
                    </Button>
                    <br />
                    <Button type="primary" icon="download" size={size} />
                    <Button
                        type="primary"
                        shape="circle"
                        icon="download"
                        size={size}
                    />
                    <Button
                        type="primary"
                        shape="round"
                        icon="download"
                        size={size}
                    />
                    <Button
                        type="primary"
                        shape="round"
                        icon="download"
                        size={size}
                    >
                        Download
                    </Button>
                    <Button type="primary" icon="download" size={size}>
                        Download
                    </Button>
                    <br />
                    <Button.Group size={size}>
                        <Button type="primary">
                            <Icon type="left" />
                            Backward
                        </Button>
                        <Button type="primary">
                            Forward
                            <Icon type="right" />
                        </Button>
                    </Button.Group>
                </div>

                <div>
                    <Button type="primary" loading>
                        Loading
                    </Button>
                    <Button type="primary" size="small" loading>
                        Loading
                    </Button>
                    <br />
                    <Button
                        type="primary"
                        loading={this.state.loading}
                        onClick={this.enterLoading}
                    >
                        Click me!
                    </Button>
                    <Button
                        type="primary"
                        icon="poweroff"
                        loading={this.state.iconLoading}
                        onClick={this.enterIconLoading}
                    >
                        Click me!
                    </Button>
                    <br />
                    <Button type="primary" loading />
                    <Button type="primary" shape="circle" loading />
                    <Button type="danger" shape="round" loading />
                </div>
            </div>
        );
    }
}
