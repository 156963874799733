import React, { useState, useEffect, useContext } from 'react';
import { Button, Table, Spin, Icon, Input } from 'antd';
import { ButtonProps } from 'antd/lib/button';
import { TableProps } from 'antd/lib/table';
import { SpinProps } from 'antd/lib/spin';
import './index.scss';
import _ from 'lodash';
import Dropdown, { DropdownButtonProps } from 'antd/lib/dropdown';
import { Empty } from 'antd';
import { EmptyProps } from 'antd/lib/empty';
import Tabs, { TabPaneProps, TabsProps } from 'antd/lib/tabs';
import Collapse, { CollapseProps, CollapsePanelProps } from 'antd/lib/collapse';
import CollapsePanel from 'antd/lib/collapse/CollapsePanel';
import { TextAreaProps } from 'antd/lib/input';
import { IDataReviewProps } from '../common/items/data-review';
import DataReview from '../common/items/data-review/index';
import { ICheckBody, useAuthPrecheck } from '@/utils/hooks/auth-precheck';
import { useVT } from 'virtualizedtableforantd';

export const AntButton: React.FC<ButtonProps & {
    checkBody?: ICheckBody;
}> = props => {
    const { checkBody } = props;
    const [nextOnClick, snippet, preCheckLoading] = useAuthPrecheck({
        checkBody,
        parentProps: props,
    });

    if (checkBody === undefined) {
        return (
            <Button
                className="maimai-crm-button"
                {..._.assign({ type: 'default' }, props)}
            />
        );
    } else {
        return (
            <>
                {snippet}
                <Button
                    className="maimai-crm-button"
                    {..._.assign({ type: 'default' }, props)}
                    onClick={nextOnClick}
                    loading={preCheckLoading || props.loading}
                />
            </>
        );
    }
};

export const AntTableCtx = React.createContext({ useVTable: false });

export const AntTable: <T>(
    props: TableProps<T>,
) => React.ReactElement<TableProps<T>> = props => {
    const conf = useContext(AntTableCtx);
    const nextProps = { ...props };
    const loading = props.loading;
    const clsname = nextProps.className;
    delete nextProps.className;

    // const [start] = useState({v: Date.now()})
    // useEffect(() => {
    //     console.log('mount time 2: ', Date.now() - start.v)
    //     start.v = Date.now()
    // }, [props.dataSource]);

    const [VT] = useVT({});
    return (
        <Table
            className={'maimai-crm-table ' + clsname}
            {...nextProps}
            // loading = {{
            //     spinning: true,
            //     indicator: <CrmLoading />
            // }}
            // loading={ loading ? {
            //     spinning: true,
            //     indicator: <CrmLoading />
            // } : false }
            components={conf.useVTable ? VT : undefined}
        />
    );
};

export const AntSpin: React.FC<SpinProps> = props => {
    return <Spin {...props} />;
};

export interface IAntDropDownButtonProps extends DropdownButtonProps {
    hasRight?: boolean;
    buttonConfig?: ButtonProps;
}
export const AntDropdownButton: React.FC<IAntDropDownButtonProps> = props => {
    const { hasRight = true, buttonConfig = {} } = props;
    return hasRight ? (
        <Dropdown.Button
            className="maimai-crm-dropdownbtn"
            {...props}
        ></Dropdown.Button>
    ) : (
        <Dropdown className="maimai-crm-dropdownbtn" {...props}>
            <AntButton {...buttonConfig} />
        </Dropdown>
    );
};

export const AntEmpty: React.FC<EmptyProps> = props => {
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
};

export interface IAntTabsConfig extends TabPaneProps {
    content?: any;
    type?: 'collapse';
    contentConfig?: any;
    visible?: boolean;
}
export interface IAntTabProps extends TabsProps {
    tabConfig?: IAntTabsConfig[];
}

export const AntTabs: React.FC<IAntTabProps> = props => {
    const { tabConfig } = props;
    if (tabConfig && tabConfig.length > 0) {
        return (
            <Tabs {...props}>
                {_.map(tabConfig, (item: IAntTabsConfig, idx) => {
                    const visible = _.isUndefined(item.visible)
                        ? true
                        : item.visible;
                    if (!visible) {
                        return null;
                    }
                    return (
                        <AntTabPane key={item.key} {...item}>
                            {item.content}
                        </AntTabPane>
                    );
                })}
            </Tabs>
        );
    } else {
        return <Tabs {...props} />;
    }
};

export const AntTabPane: React.FC<TabPaneProps> = props => {
    return <Tabs.TabPane {...props} />;
};

export interface IAntCollapseConfig extends CollapsePanelProps {
    content: any;
    contentType?: 'data-review'; // TODO: 现在只有data-review
    dataReviewProps?: IDataReviewProps;
}
export interface IAntCollapseProps extends CollapseProps {
    collapseConfig?: IAntCollapseConfig[];
}

export const AntCollapse: React.FC<IAntCollapseProps> = props => {
    const [activeKey, setActiveKey] = useState<string[] | string>([]);
    const { collapseConfig, defaultActiveKey } = props;
    useEffect(() => {
        setActiveKey((defaultActiveKey as string[]) || []);
    }, [defaultActiveKey]);
    const renderContent = (item: IAntCollapseConfig) => {
        if (item.contentType === 'data-review') {
            if (item.dataReviewProps) {
                return <DataReview {...item.dataReviewProps} />;
            } else {
                throw new Error('ban use contentType without typeProps');
            }
        } else {
            return item.content;
        }
    };
    if (collapseConfig && collapseConfig.length > 0) {
        return (
            <Collapse
                activeKey={activeKey}
                onChange={key => {
                    setActiveKey(key);
                }}
                {...props}
                className="maimai-crm-collapse"
                bordered={false}
                expandIconPosition="left"
                expandIcon={({ isActive }) => (
                    <Icon type="caret-right" rotate={isActive ? 90 : 0} />
                )}
            >
                {_.map(collapseConfig, (item: IAntCollapseConfig, idx) => {
                    return (
                        <AntCollapsePanel {...item}>
                            {renderContent(item)}
                        </AntCollapsePanel>
                    );
                })}
            </Collapse>
        );
    } else {
        return <Collapse {...props} />;
    }
};

export interface IAntCollapseConfig extends CollapsePanelProps {
    content: any;
    contentType?: 'data-review'; // TODO: 现在只有data-review
    dataReviewProps?: IDataReviewProps;
    hidden?: boolean;
}
export interface IAntCollapseProps2 extends CollapseProps {
    collapseConfig?: IAntCollapseConfig[];
    onInnerChange?: (keys: string[]) => void;
}

export const AntCollapse2: React.FC<IAntCollapseProps2> = props => {
    const { collapseConfig } = props;
    const renderContent = (item: IAntCollapseConfig) => {
        if (item.contentType === 'data-review') {
            if (item.dataReviewProps) {
                return <DataReview {...item.dataReviewProps} />;
            } else {
                throw new Error('ban use contentType without typeProps');
            }
        } else {
            return item.content;
        }
    };
    if (collapseConfig && collapseConfig.length > 0) {
        console.log('nextOpensnextOpens', 'ccc');

        return (
            <Collapse
                {...props}
                className="maimai-crm-collapse"
                bordered={false}
                expandIconPosition="left"
                expandIcon={({ isActive }) => (
                    <Icon type="caret-right" rotate={isActive ? 90 : 0} />
                )}
            >
                {_.map(collapseConfig, (item: IAntCollapseConfig, idx) => {
                    if (item.hidden) {
                        return null;
                    }
                    return (
                        <AntCollapsePanel {...item}>
                            {renderContent(item)}
                        </AntCollapsePanel>
                    );
                })}
            </Collapse>
        );
    } else {
        return <Collapse {...props} />;
    }
};

export const AntCollapsePanel: React.FC<CollapsePanelProps & {
    isActive?: boolean;
}> = props => {
    const renderHeader = () => {
        console.log(props);
        const { isActive } = props;
        return (
            <div className="crm-collapse-header">
                {props.header}
                <Icon
                    style={{ color: '#D1D3DE', marginLeft: '5px' }}
                    type="caret-right"
                    rotate={isActive ? 90 : 0}
                />
            </div>
        );
    };
    return (
        <CollapsePanel showArrow={false} {...props} header={renderHeader()} />
    );
};

export interface IAntInputTextArea extends TextAreaProps {
    showCount?: boolean;
    extraFooter?: any;
}

export const AntInputTextArea: React.FC<IAntInputTextArea> = props => {
    const { showCount = false, maxLength, onChange, extraFooter } = props;
    const [count, setCount] = useState(0);
    const inputChangeHandle = (
        event: React.ChangeEvent<HTMLTextAreaElement>,
    ) => {
        const length = event.target.value.length;
        setCount(length);
        if (onChange) {
            onChange(event);
        }
    };
    return (
        <div className="crm-inputarea-content">
            <Input.TextArea {...props} onChange={inputChangeHandle} />
            {extraFooter}
            {showCount && (
                <span className="count">{`${count}/${maxLength}`}</span>
            )}
        </div>
    );
};

export { Button };
